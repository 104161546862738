import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import {
  ClientConfigService,
  UpdateUserConsentsRequest,
  UserBrandConsent,
  UserConsentsResponse,
  UserDealerConsent,
  UserGroupConsent,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'
import { B2CConfig } from '@inside-hub-app/customer-portal-config'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
@Component({
  selector: 'customer-portal-app-consents-view',
  templateUrl: './consents-view.component.html'
})
export class ConsentsViewComponent implements OnInit {
  constructor (
    private readonly userService: UserService,
    private readonly clientConfig: ClientConfigService,
    public router: ActivatedRoute,
    private readonly logger: NGXLogger,
    private readonly transifexTranslationsService: TranslationService,
    public transifexService: TransifexService,
    private readonly remoteConfigService: EfRemoteConfigurationService<B2CConfig>
  ) {
    this.emailLink = this.remoteConfigService.get('footer.emailLink')
    this.consentLink = this.remoteConfigService.get('footer.consentLink')
  }

  consentsResponse: UserConsentsResponse
  brands: UserBrandConsent[]
  dealers: UserDealerConsent[]
  // group: any = { "group": { "id": 1, "name": "EmilFrey", "consents": { "type": "document", "signed": true, "channels": [{ "name": "email", "allow": true }, { "name": "post", "allow": false }, { "name": "telephone", "allow": true }] } }, "dealers": [{ "id": 6417, "name": "Schwabengarage GmbH", "street": "Cannstatter Str. 46", "zip": "70190", "city": "Stuttgart", "country": "de", "consents": { "type": "document", "signed": true, "channels": [{ "name": "email", "allow": false }, { "name": "post", "allow": true }, { "name": "telephone", "allow": true }] } }], "brands": [{ "id": 372, "name": "FORD", "street": "Henry-Fordstrasse 1", "zip": "50735", "city": "Köln", "country": "de", "consents": { "type": "document", "signed": true, "channels": [{ "name": "email", "allow": false }, { "name": "post", "allow": true }, { "name": "telephone", "allow": false }] } }] }
  group: any
  updatedBrands: UserBrandConsent[]
  updatedDealers: UserDealerConsent[]
  updatedGroup: UserGroupConsent
  disabled: boolean

  public consents
  public emailLink
  public consentLink
  public selectedChannels: string[]
  private href: any
  private apiKey: ''
  private redirectUrl: ''
  public loading = false
  public channels: any

  ngOnInit (): void {
    this.router.queryParams.subscribe(param => {
      this.redirectUrl = param.redirect_url
      this.apiKey = param.api_key
    })

    this.updatedGroup = this.group.group

    this.userService.getConsents(null, this.apiKey).subscribe(consents => {
      this.consentsResponse = consents

      this.dealers = this.consentsResponse.dealers
      this.group = this.consentsResponse.group

      this.brands = this.consentsResponse.brands
      this.logger.debug(this.consentsResponse)
      this.updatedDealers = this.dealers
      this.updatedGroup = this.group
      this.updatedBrands = this.brands
    })
  }

  updateConsents () {
    this.loading = true
    this.disabled = true
    this.href = window.location.href
    const newConsents: UserConsentsResponse = {
      group: this.updatedGroup,
      dealers: this.updatedDealers,
      brands: this.updatedBrands
    }

    const updateConsents: UpdateUserConsentsRequest = {
      url: this.href,
      userConsents: newConsents
    }

    this.clientConfig.get(this.apiKey).subscribe(data => {
      const regex = RegExp(data.allowedRedirectUris).test(this.redirectUrl)
      if (regex) {
        this.userService.putConsents(updateConsents, this.apiKey).subscribe(
          text => {
            this.logger.debug(text)
            this.disabled = false
            window.location.href = this.redirectUrl
          },
          error => {
            this.loading = false
            this.logger.error(error)
          }
        )
      }
    })
  }

  updateConsentValue (consent: string, id: number, type: string, event) {
    if (consent === 'dealer') {
      const consentValue = this.updatedDealers
        .find(d => d.id === id)
        .consents.channels.find(cd => cd.name === type)
      consentValue.allow = event.checked
      this.logger.debug(
        this.updatedDealers
          .find(d => d.id === id)
          .consents.channels.find(cd => cd.name === type).allow
      )
    } else if (consent === 'brand') {
      const consentValue = this.updatedBrands
        .find(b => b.id === id)
        .consents.channels.find(cb => cb.name === type)
      consentValue.allow = event.checked
      this.logger.debug(
        this.updatedBrands
          .find(b => b.id === id)
          .consents.channels.find(cb => cb.name === type).allow
      )
    } else {
      const consentValue = this.updatedGroup.consents.channels.find(
        cg => cg.name === type
      )
      consentValue.allow = event.checked

      this.logger.debug(
        this.updatedGroup.consents.channels.find(cg => cg.name === type)
      )
    }
  }

  getConsentGroup (name: string, type: string) {
    return this.group.consents.channels.find(s => s.name === type).allow
  }

  getTranslation1 (): string {
    return this.transifexTranslationsService.translate(
      'For advertising purposes, invitations to product presentations and test drives as well as for market research purposes, including satisfaction surveys. For this purpose, the personal data provided are evaluated and analyzed in order to be able to offer you a customer-specific approach and tailored offers.',
      {
        _key: 'customerPortal.b2c.consents.gdpr-notice1',
        _tags: 'cpt-auth, 3.1',
        email: this.emailLink
      }
    )
  }

  getTranslation2 (): string {
    return this.transifexTranslationsService.translate(
      'The declaration of consent can be revoked or changed at any time for the future and without giving reasons. Simply send a message to {email} (e.g. with the email you have given us).',
      {
        _key: 'customerPortal.b2c.consents.gdpr-notice2',
        _tags: 'cpt-auth, 3.1',
        email: this.emailLink
      }
    )
  }

  getTranslation3 (): string {
    return this.transifexTranslationsService.translate(
      'Further information on data protection and your rights as a data subject can be found in the {link}.',
      {
        _key: 'customerPortal.b2c.consents.gdpr-notice3',
        _tags: 'cpt-auth, 3.1',
        link: this.consentLink
      }
    )
  }
}
