import { HttpClient, HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { type Observable, map } from 'rxjs'
import { Environment } from '../environments'
import { ENVIRONMENT } from '../injection-tokens'
import { type JsonApiResponse, type JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { JsonApiRequest, JsonApiRequestData } from '../models/jsonApiRequest.model'
import { EfDatatableSortDirection } from '../enum/efDatatable.enum'
import type { EfDatatableSortEvent } from '../models/efDatatable.model'
import type { com } from 'efd-cdb_backend-interfaces-ts'
import type {
  CdbUserLocationCandidateDTO,
  CdbUserLocationDTO,
  CdbUserLocationInfoDTO,
  ExtCdbUserLocationDTO,
  UserLocationCandidatesParams
} from '../models/user-dealer.model'
import { FunctionTrainingEducationTypes } from '../enum/functions-training-education.enum'

type CdbUserMinDTO = com.mocira.inside.cdb.client.dto.CdbUserMinDTO
type CdbDealerUserDTO = com.mocira.inside.cdb.client.dto.CdbDealerUserDTO
type CdbDealerUserQualificationDTO = com.mocira.inside.cdb.client.dto.CdbDealerUserQualificationDTO
type WebDepartmentDTO = com.mocira.inside.cdb.client.dto.CdbWebDepartmentDTO
type LocationWebRolesDTO = com.mocira.inside.cdb.client.dto.CdbLocationWebRolesDTO
type CdbUserTeamsDTO = com.mocira.inside.cdb.rest.dto.CdbUserTeamsDTO
type UserEmployeeDTO = com.mocira.inside.cdb.client.dto.UserEmployeeDTO

export enum TeamType {
  TEAM = 'TEAM_MEMBERSHIP',
  ENTITY = 'ENTITY_MEMBERSHIP'
}

export interface UserBrandsRequest {
  userId: number
  dealerId: number
  brandIds: number[]
}

export interface EmployeeGridParameters {
  id: number
  size: number
  number: number
  sort: string
  languages: string[]
  gender: string
  status: string[]
  salutation: string
  brands: string[]
  functions: string[]
  locations: string[]
  roles: string[]
  adminRoles: string[]
  search: string
}

@Injectable({
  providedIn: 'root'
})

export class UserDealerService {
  private readonly apiUrl: string

  constructor (
    private readonly http: HttpClient,
    @Inject(ENVIRONMENT) private readonly environment: Environment
  ) {
    this.apiUrl = this.environment.baseUrl
  }

  public getDealerUser (dealerUserId: number): Observable<CdbDealerUserDTO> {
    return this.http.get<CdbDealerUserDTO>(
      `${this.apiUrl}/cdb/user/dealer/${dealerUserId}`
    )
  }

  public deleteDealerUser (dealerUserId: number): Observable<CdbDealerUserDTO> {
    return this.http.delete<CdbDealerUserDTO>(
      `${this.apiUrl}/cdb/user/dealer/${dealerUserId}`
    )
  }

  public addUserDealer (data: CdbDealerUserDTO): Observable<CdbDealerUserDTO> {
    return this.http.post<CdbDealerUserDTO>(
      `${this.apiUrl}/cdb/user/dealer`, data
    )
  }

  getUserLocationsCandidates (
    userId: number,
    parameters?: UserLocationCandidatesParams
  ): Observable<JsonApiResponseList<CdbUserLocationCandidateDTO>> {
    let params = new HttpParams()

    if (parameters?.search !== undefined) {
      params = params.append('search', parameters.search)
    }

    if (parameters?.maxResults !== undefined) {
      params = params.append('maxResults', parameters.maxResults)
    }

    return this.http.get<JsonApiResponseList<CdbUserLocationCandidateDTO>>(
      `${this.apiUrl}/cdb/users/${userId}/locations-candidates`, { params }
    )
  }

  addUserLocation (location: ExtCdbUserLocationDTO): Observable<JsonApiResponse<CdbUserLocationDTO>> {
    const requestData = JsonApiRequest.of(location)
    return this.http.post<JsonApiResponse<CdbUserLocationDTO>>(`${this.apiUrl}/cdb/users/locations`, requestData)
  }

  deleteUserLocation (dealerUserId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/cdb/users/locations/${dealerUserId}`)
  }

  updateUserLocation (userLocations: CdbUserLocationInfoDTO[]): Observable<JsonApiResponseList<CdbUserLocationInfoDTO>> {
    const wrappedRequests = userLocations.map(location =>
      new JsonApiRequestData(location)
    )
    const requestData = {
      data: wrappedRequests
    }
    return this.http.put<JsonApiResponseList<CdbUserLocationInfoDTO>>(
      `${this.apiUrl}/cdb/user/dealers/location-options`,
      requestData
    )
  }

  public getManagers (userDealerId: number, managerId: number, deputyId: number): Observable<CdbUserMinDTO[]> {
    const params: any = {}
    if (managerId) params.managerId = managerId.toString()
    if (deputyId) params.deputyId = deputyId.toString()

    return this.http.get<CdbUserMinDTO[]>(
      `${this.apiUrl}/cdb/user/dealer/${userDealerId}/manager`,
      { params }
    )
  }

  public getManagerCandidates (userDealerId: number): Observable<CdbUserMinDTO[]> {
    return this.http.get<CdbUserMinDTO[]>(
      `${this.apiUrl}/cdb/user/dealer/${userDealerId}/manager/candidates`
    )
  }

  /**
   * Get all enabled employees of a partner or multiple partners optionally filtered by roles
   */
  public getPartnersEmployees (partnersId: number[], roles: string[] = []): Observable<CdbUserMinDTO[]> {
    let params = new HttpParams()

    if (partnersId?.length > 0) {
      partnersId.forEach(partnerId => {
        params = params.append('dealerId', partnerId)
      })
    }

    if (roles?.length > 0) {
      roles.forEach(role => {
        params = params.append('roles', role)
      })
    }

    return this.http.get<CdbUserMinDTO[]>(
      `${this.apiUrl}/cdb/users/dealers/`, { params }
    )
  }

  public getDealerUserQualifications (parameters, brandId: number, userId: number): Observable<JsonApiResponse<CdbDealerUserQualificationDTO>> {
    let params = new HttpParams()

    for (const [key, value] of Object.entries(parameters)) {
      if (key === 'functionSettingsType' && Array.isArray(value)) {
        value.forEach((val: string) => {
          params = params.append('filter[functionSettingsType]', val)
        })
      } else {
        if (value !== null) params = params.append(key, value as string)
      }
    }

    return this.http.get<JsonApiResponse<CdbDealerUserQualificationDTO>>(
      `${this.apiUrl}/cdb/brand-qualifications/brands/${brandId}/users/${userId}`,
      { params }
    )
  }

  public saveDealerUserQualification (brandId: number, userId: number, qualificationType: string, data, functionSettingsTypes: string[]): Observable<JsonApiResponse<CdbDealerUserQualificationDTO>> {
    let params = new HttpParams()

    if (qualificationType !== FunctionTrainingEducationTypes.TRAINING_EDUCATION) {
      functionSettingsTypes.forEach((type: string) => {
        params = params.append('functionSettingsType', type)
      })
    }

    const requestData = new JsonApiRequest(new JsonApiRequestData(data))

    return this.http.post<JsonApiResponse<CdbDealerUserQualificationDTO>>(
      `${this.apiUrl}/cdb/brand-qualifications/brand/${brandId}/users/${userId}/${qualificationType}`,
      requestData,
      { params }
    )
  }

  public getDepartmentWebRoles (): Observable<WebDepartmentDTO[]> {
    return this.http
      .get<JsonApiResponseList<WebDepartmentDTO>>(`${this.apiUrl}/cdb/department/web-roles`)
      .pipe(map(res => res.data.map(el => el.attributes)))
  }

  public getUserDealerWebRoles (userId: number, dealerId: number): Observable<LocationWebRolesDTO> {
    return this.http
      .get<JsonApiResponse<LocationWebRolesDTO>>(`${this.apiUrl}/cdb/user/${userId}/dealer/${dealerId}/web-roles`)
      .pipe(map(res => res.data.attributes))
  }

  public setUserDealerWebRoles (
    userId: number,
    dealerId: number,
    roleId: number,
    isSet: string
  ): Observable<JsonApiResponse<LocationWebRolesDTO>> {
    const requestData = new JsonApiRequest(new JsonApiRequestData({ id: roleId }))

    return this.http
      .put<JsonApiResponse<LocationWebRolesDTO>>(
      `${this.apiUrl}/cdb/user/${userId}/dealer/${dealerId}/web-roles?isSet=${isSet}`,
      requestData
    )
  }

  getUserTeams (
    id: number,
    dealerId: number,
    thisLocationOnly: boolean = true,
    type?: TeamType
  ): Observable<JsonApiResponseList<CdbUserTeamsDTO>> {
    const params = {
      thisLocationOnly,
      type
    }
    if (type !== undefined) params.type = type

    return this.http.get<JsonApiResponseList<CdbUserTeamsDTO>>(
      `${this.apiUrl}/cdb/user/${id}/dealer/${dealerId}/teams`,
      { params }
    )
  }

  getAllUsersByDealerByBrand (dealerExternalId: number, brandId: number): Observable<any> {
    let param = '?'
    if (brandId) param += 'brand=' + brandId

    return this.http.get(`${this.apiUrl}/cdb/dealer/${dealerExternalId}/users/externalId${param}`)
  }

  getReducedUsersByDealerByBrand (dealerId: number, brandId: number): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/cdb/audit/dealers/${dealerId}/users?platform=INSiDE&brand=${brandId}`
    )
  }

  getUsersByDealerEfitId (dealerEfitId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/cdb/dealer/${dealerEfitId}/users/externalId`)
  }

  updateUserBrands (dealerId: number, userId: number, data: UserBrandsRequest): Observable<any> {
    const bodyData = new JsonApiRequest(new JsonApiRequestData(data))
    return this.http.post(
      `${this.apiUrl}/cdb/users/${userId}/dealers/${dealerId}/brands`,
      bodyData
    )
  }

  getEmployeeList (
    filterColumn?: EfDatatableSortEvent,
    parameters?: Partial<EmployeeGridParameters>,
    dealershipEnabled?: boolean
  ): Observable<any> {
    let params

    if (parameters) {
      params = this.setParameters(parameters)
    }

    if (filterColumn?.column) {
      const filterPrefix =
        filterColumn.direction === EfDatatableSortDirection.DESCENDING
          ? '-'
          : ''
      params['sort'] = filterPrefix + filterColumn.column
    }

    // setup brands filter
    if (parameters.brands)
      params['filter[brands]'] = this.setupBrandsParameters(parameters.brands)

    // send this filter to get all employees even if the dealer is disabled
    if (!dealershipEnabled) {
      params['locationEnabled'] = false
    }

    const config = { params }
    return this.http.get<JsonApiResponseList<UserEmployeeDTO>>(
      `${this.apiUrl}/cdb/user/dealer/list`,
      config
    )
  }

  setParameters (parameters: Partial<EmployeeGridParameters>) {
    const params = {}

    Object.keys(parameters).forEach(function (key) {
      if (parameters[key] !== undefined && parameters[key] !== null) {
        const filter = key === 'size' || key === 'number' ? 'page' : 'filter'
        let filterKey = ''
        if (key === 'search') {
          filterKey = filter + '[' + key + '][LIKE]'
        } else if (key === 'status') {
          filterKey = filter + '[' + key + '][IN]'
        } else {
          filterKey = filter + '[' + key + ']'
        }
        params[filterKey] = parameters[key]
      }
    })

    return params
  }

  setupBrandsParameters (brandsIds: string[]): string {
    let brandsString = ''
    for (const brandId of brandsIds) {
      brandsString += brandId + ','
    }
    return brandsString.slice(0, -1)
  }

  exportUsers (parameters: Partial<EmployeeGridParameters>, lang?: string): Observable<any> {
    let params
    // Delete page and table parameters - not needed for export
    if (parameters['size']) delete parameters.size
    if (parameters['number']) delete parameters.number
    if (parameters['sort']) delete parameters.sort

    if (parameters) {
      params = this.setParameters(parameters)
    }

    if (lang) {
      params['language'] = lang
    }

    // setup brands filter
    if (parameters.brands) {
      params['filter[brands]'] = this.setupBrandsParameters(parameters.brands)
    }

    return this.http.get(`${this.apiUrl}/cdb/export/employees`, {
      responseType: 'blob',
      params
    })
  }
}
