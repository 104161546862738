import { Injectable } from '@angular/core'
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { UserPartnerContextService } from '@inside-hub-app/hub-user-partner-context'
import { mergeMap } from 'rxjs/operators'
import type { hr } from 'efd-cdb_backend-interfaces-ts'

type CdbUserContextPartnerInfoDto = hr.emilfreydigital.cdb.dto.CdbUserContextPartnerInfoDto

const DEALER_HEADER = 'X-Dealer-Id'

@Injectable()

export class PpDealerIdInterceptor implements HttpInterceptor {
  constructor (
    private readonly userPartnerContextService: UserPartnerContextService
  ) {}

  intercept (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.getCurrentDealer().pipe(
      mergeMap(currentDealer => {
        if (!currentDealer) {
          return next.handle(request)
        }
        const id = request.headers?.get(DEALER_HEADER) || currentDealer.id.toString()
        const cloneRequest = request.clone({
          setHeaders: {
            [DEALER_HEADER]: id
          }
        })

        return next.handle(cloneRequest)
      })
    )
  }

  getCurrentDealer (): Observable<CdbUserContextPartnerInfoDto> {
    return this.userPartnerContextService.getCurrentPartner()
  }
}
