import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import type { JsonApiResponse, JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import type { hr, com } from 'efd-cdb_backend-interfaces-ts'
import { type Observable, of } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { JsonApiRequest, JsonApiRequestData } from '../models/jsonApiRequest.model'
import { BaseService } from './base.service'
import { toString } from 'lodash'

type UserProfileDTO = hr.emilfreydigital.cdb.userprofile.dto.UserProfileDTO
type UserDTO = com.mocira.inside.cdb.client.dto.CdbUserDTO
type UserEducationDTO = com.mocira.inside.cdb.client.dto.CdbUserEducationDTO
type CdbUserInfoDTO = com.mocira.inside.cdb.client.dto.CdbUserInfoDTO
type CdbUserMailParamsDTO = hr.emilfreydigital.cdb.dto.CdbUserMailParamsDTO
type CdbCarmarketWebSettingsDTO = com.mocira.inside.cdb.client.dto.CdbCarmarketWebSettingsDTO
type CdbDealerUserDTO = com.mocira.inside.cdb.client.dto.CdbDealerUserDTO
type CdbUserNotificationDTO = com.mocira.inside.cdb.client.dto.CdbUserNotificationDTO
type CdbUserLocationDTO = com.mocira.inside.cdb.client.dto.CdbUserLocationDTO
type CdbUserLocationInfoDTO = com.mocira.inside.cdb.rest.dto.CdbUserLocationInfoDTO
type CdbDealerUserDepartmentVisibilityDTO = com.mocira.inside.cdb.client.dto.CdbDealerUserDepartmentVisibilityDTO
type CreateUserDTO = com.mocira.inside.cdb.client.dto.CdbCreateUserDTO
type CdbUserGeneralInfoDTO = com.mocira.inside.cdb.client.dto.CdbUserGeneralInfoDTO

export interface UserActivation {
  kcResponse: boolean
  userEnabled: boolean
  id: number
}

interface invitationMailParams {
  invitationUrl: string
  supportUrl: string
  appUrl: string
}

interface UserStatus {
  id: number
  name: string
}

interface UserStatuses {
  id: number
  modifiedDt: number
  name: string
}

export interface AutomaticProfileUpdateModel {
  id?: string
  enabled: boolean
  status: 'Scheduled' | 'Disabled'
  properties?: string
}

@Injectable({
  providedIn: 'root'
})

export class UserService extends BaseService {
  private _user: UserDTO

  /**
  * @param ignoreFilter used to get the data even though the dealership of the user is disabled (for exmaple the userBrands)
  */
  getUser (userId: number, ignoreFilter?: boolean): Observable<UserDTO> {
    let params = new HttpParams()

    if (ignoreFilter) {
      params = params.append('ignoreFilter', true)
    }

    return this.http.get<UserDTO>(`${this.apiUrl}/cdb/user/${userId}`, { params })
  }

  getStoredUser (): Observable<UserDTO> {
    if (this._user) {
      return of(this._user)
    }

    return this.storage.get<UserDTO>(this.storagePrefix + 'user').pipe(
      map(user => user),
      tap(user => {
        this._user = user
      })
    )
  }

  store (user: UserDTO) {
    this._user = user
    return this.storage.set(this.storagePrefix + 'user', user)
  }

  removeFromStorage () {
    this._user = null
    return this.storage.delete(this.storagePrefix + 'user')
  }

  updateUser (user: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/cdb/user/${user.id}`, user)
  }

  userActivation (userId: number, enabled: boolean, kc: boolean, dealerId?: number, brands?: number[]): Observable<UserActivation> {
    const body = { enabled }
    const params = { kc }

    if (dealerId) params['dealerId'] = dealerId
    if (brands) params['brandId'] = brands

    return this.http.put<UserActivation>(`${this.apiUrl}/cdb/users/${userId}/activation-status`, body, { params: params })
  }

  getUserProfile (userId: number): Observable<JsonApiResponse<UserProfileDTO>> {
    return this.http.get<JsonApiResponse<UserProfileDTO>>(
      `${this.apiUrl}/cdb/user/profile/${userId}`
    )
  }

  saveUserProfile (userId: number, user: UserProfileDTO): Observable<JsonApiResponse<UserProfileDTO>> {
    const requestData = new JsonApiRequest(new JsonApiRequestData(user))
    const config = { headers: { 'Content-Type': 'application/vnd.api+json' } }

    return this.http.put<JsonApiResponse<UserProfileDTO>>(`${this.apiUrl}/cdb/user/profile/${userId}`, requestData, config)
  }

  saveUserTermsAndConditions (userId: number): Observable<JsonApiResponse<UserProfileDTO>> {
    const config = { headers: { 'Content-Type': 'application/vnd.api+json' } }

    return this.http.put<JsonApiResponse<UserProfileDTO>>(`${this.apiUrl}/cdb/user/profile/${userId}/toc`, {}, config)
  }

  invalidateUser (userId: number): Observable<UserDTO> {
    return this.http.post(`${this.apiUrl}/cdb/security/invalidate/user/${userId}`, {})
  }

  deactivateUser (userId: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/cdb/security/disable/user/${userId}`, {})
  }

  activateUser (userId: number): Observable<UserDTO> {
    return this.http.post(`${this.apiUrl}/cdb/security/activate/user/${userId}`, {})
  }

  getUserEducations (userId: number): Observable<JsonApiResponseList<UserEducationDTO>> {
    return this.http.get<JsonApiResponseList<UserEducationDTO>>(
      `${this.apiUrl}/cdb/user/${userId}/educations`
    )
  }

  addUserEducation (data: UserEducationDTO): Observable<JsonApiResponse<UserEducationDTO>> {
    const requestData = new JsonApiRequest(new JsonApiRequestData(data))

    return this.http.post<JsonApiResponse<UserEducationDTO>>(
      `${this.apiUrl}/cdb/user/educations`,
      requestData
    )
  }

  deleteUserEducation (educationId: number): Observable<JsonApiResponse<UserEducationDTO>> {
    return this.http.delete<JsonApiResponse<UserEducationDTO>>(
      `${this.apiUrl}/cdb/user/educations/${educationId}`
    )
  }

  getUserDealerBrands (
    userId: number,
    dealerId?: number,
    dealershipEnabled?: boolean
  ): Observable<CdbUserInfoDTO> {
    let params = new HttpParams()
    if (dealerId) params = params.append('dealerId', dealerId)
    if (dealershipEnabled === false) {
      params = params.append('ignoreFilter', true)
    }

    return this.http.get<CdbUserInfoDTO>(`${this.apiUrl}/cdb/user/${userId}/info`, { params })
  }

  sendInvitation (userId: number, params: invitationMailParams): Observable<CdbUserMailParamsDTO> {
    return this.http.post<CdbUserMailParamsDTO>(`${this.apiUrl}/cdb/users/${userId}/invitation-mail/send`, params)
  }

  createUser (newUser: CreateUserDTO): Observable<JsonApiResponse<CreateUserDTO>> {
    const wrappedData = JsonApiRequest.of(newUser)
    return this.http.post<JsonApiResponse<CreateUserDTO>>(`${this.apiUrl}/cdb/users/v2`, wrappedData)
  }

  emailUnique (email: string): Observable<JsonApiResponseList<UserDTO>> {
    return this.http.get<JsonApiResponseList<UserDTO>>(`${this.apiUrl}/cdb/users/email/${email}`)
  }

  usernameUnique (username: string): Observable<JsonApiResponseList<UserDTO>> {
    return this.http.get<JsonApiResponseList<UserDTO>>(`${this.apiUrl}/cdb/user/username/${username}`)
  }

  updateUserCarmarketWebVisibility (userId: number, dealerId: number, data: CdbCarmarketWebSettingsDTO): Observable<JsonApiResponse<CdbCarmarketWebSettingsDTO>> {
    const wrappedData = JsonApiRequest.of(data)
    return this.http.post<JsonApiResponse<CdbCarmarketWebSettingsDTO>>(`${this.apiUrl}/cdb/users/${userId}/dealer/${dealerId}/carmarket-web-settings`, wrappedData)
  }

  deleteUser (userId: number): Observable<UserDTO> {
    return this.http.delete<UserDTO>(`${this.apiUrl}/cdb/users/${userId}`)
  }

  getUserStatus (userId: number): Observable<UserStatus> {
    return this.http.get<UserStatus>(this.apiUrl + `/cdb/user/status/${userId}`)
  }

  getUserStatusList (): Observable<UserStatuses[]> {
    return this.http.get<UserStatuses[]>(this.apiUrl + '/cdb/user/status/list')
  }

  setUserStatus (userId: number, statusId: number): Observable<any> {
    return this.http.put(`${this.apiUrl}/cdb/user/status/${userId}?statusId=${statusId}`, {})
  }

  getDealerUser (dealerUserId: number): Observable<CdbDealerUserDTO> {
    return this.http.get<CdbDealerUserDTO>(`${this.apiUrl}/cdb/user/dealer/${dealerUserId}`)
  }

  setDealerUserGDPRConsent (dealerUserId: number, gdprConsent: boolean): Observable<any> {
    let param = ''
    if (gdprConsent) param += '?gdprConsent=' + gdprConsent

    return this.http.get(`${this.apiUrl}/cdb/user/dealer/${dealerUserId}/gdpr-consent${param}`)
  }

  getUserNotifications (
    userId: number
  ): Observable<JsonApiResponseList<CdbUserNotificationDTO>> {
    return this.http.get<JsonApiResponseList<CdbUserNotificationDTO>>(
      this.apiUrl + `/cdb/user/${userId}/notification-settings`
    )
  }

  setUserNotification (
    data: CdbUserNotificationDTO
  ): Observable<JsonApiResponseList<CdbUserNotificationDTO>> {
    const wrappedData = JsonApiRequest.of(data)
    return this.http.post<JsonApiResponseList<CdbUserNotificationDTO>>(
      this.apiUrl + '/cdb/user/notification-settings',
      wrappedData
    )
  }

  getAllUserLocations (
    userId: number,
    enabledLocations?: boolean
  ): Observable<JsonApiResponseList<CdbUserLocationDTO>> {
    let params = new HttpParams()
    if (enabledLocations === true) {
      params = params.append('enabled', true)
    }

    return this.http.get<JsonApiResponseList<CdbUserLocationDTO>>(
      `${this.apiUrl}/cdb/users/${userId}/locations`, { params }
    )
  }

  getUserLocations (
    dealerUserId: number,
    dealershipEnabled: boolean
  ): Observable<JsonApiResponseList<CdbUserLocationInfoDTO>> {
    let params = new HttpParams()
    if (!dealershipEnabled) {
      params = params.append('enabled', false)
    }

    return this.http.get<JsonApiResponseList<CdbUserLocationInfoDTO>>(
      `${this.apiUrl}/cdb/user/dealers/${dealerUserId}/locations`, { params }
    )
  }

  updateUserLocationInfo (
    userLocation: CdbUserLocationInfoDTO,
    dealerUserId: number
  ): Observable<JsonApiResponse<CdbUserLocationInfoDTO>> {
    const wrappedData = JsonApiRequest.of(userLocation)
    return this.http.put<JsonApiResponse<CdbUserLocationInfoDTO>>(
      `${this.apiUrl}/cdb/user/dealers/${dealerUserId}/locations`,
      wrappedData
    )
  }

  getPublisherStatus (dealerId: number, userId: number, key: string): Observable<any> {
    const config = {
      params: { key }
    }
    return this.http.get(
      `${this.apiUrl}/cdb/dealer/${dealerId}/user/${userId}/publisher-acl/inside`,
      config
    )
  }

  updatePublisherStatus (
    dealerId: number,
    userId: number,
    key: string,
    update: boolean
  ): Observable<any> {
    const queryFilter = {
      key,
      u: toString(update)
    }

    const params = new HttpParams({ fromObject: queryFilter })

    return this.http.put(
      `${this.apiUrl}/cdb/dealer/${dealerId}/user/${userId}/publisher-acl/inside`,
      null,
      { params }
    )
  }

  getWebDepartments (dealerUserId: number): Observable<JsonApiResponseList<CdbDealerUserDepartmentVisibilityDTO>> {
    return this.http.get<JsonApiResponseList<CdbDealerUserDepartmentVisibilityDTO>>(
      `${this.apiUrl}/cdb/users/dealers/${dealerUserId}/departments-visibility`
    )
  }

  unlockUser (userId: number): Observable<any> {
    const params = new HttpParams()
    params.append('locked', false)
    return this.http.put(`${this.apiUrl}/cdb/users/${userId}/login-status`, { params })
  }

  addExistingUser (userData): Observable<any> {
    return this.http.post(`${this.apiUrl}/cdb/users/dealers`, userData)
  }

  getUserACL (dealerId: number, userId: number): Observable<any> {
    const params: any = {
      userId
    }
    if (dealerId) {
      params.dealerId = dealerId
    }
    return this.http.get(
      `${this.apiUrl}/cdb/acl-roles/list?`,
      { params })
  }

  searchUserByName (term: string, limit: number = 10): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/cdb/user/search?`,
      {
        params: {
          text: term,
          limit
        }
      })
  }

  reset2FA (enable: boolean, userId?: number): Observable<any> {
    const params = {}
    if (enable !== null) params['enabled'] = enable
    if (userId) params['userId'] = userId

    return this.http.post(`${this.apiUrl}/cdb/security/reset2fa`, {}, { params })
  }

  resetKc2FA (enable: boolean, userId?: number): Observable<any> {
    const params = {}
    if (enable !== null) {
      params['enabled'] = enable
      params['reset'] = false
    } else {
      params['enabled'] = true
      params['reset'] = true
    }
    if (userId) params['userId'] = userId

    return this.http.post(
      `${this.apiUrl}/cdb/security/kc/reset2fa`,
      {},
      { params }
    )
  }

  /**
   * request password recovery email for specified user, returns 400 if user has no email or duplicate email
   * @param username - username of current user
   */
  initializePasswordRecovery (username: string, email: string, language = 'de', supportUrl?: string, kcEnabled?: boolean): Observable<any> {
    const recoveryUrl = new URL(location.href)

    let params = new HttpParams()
      .set('lang', language)
      .set('emailRecoveryUrl', recoveryUrl.origin + recoveryUrl.pathname)
      .set('email', email)

    if (supportUrl !== '') params = params.append('supportUrl', supportUrl)
    if (kcEnabled === true) params = params.append('fromKc', true)
    return this.http.get(`${this.apiUrl}/cdb/security/pwd-reset/${username}`, { params })
  }

  /**
   * Used for final step of password recovery, user enters new password
   * @param password - new password
   * @param token - token retrieved from e-mail recovery URL
   * @param language - system language
   */
  public passwordReset (password: string, token: string, language: string, invite?: boolean, id?: number): Observable<any> {
    // prepare header
    const config = {
      headers: {
        'X-Auth-Token': token
        //  'Content-Type': 'application/json;charset=UTF-8'
      },
      params: {
        lang: language
      }
    }

    if (invite) {
      config.params['invite'] = true
    }
    // prepare payload
    const payload = { password: password }

    if (id) {
      payload['id'] = id
    }
    return this.http.put(`${this.apiUrl}/cdb/security/pwd-reset`, payload, config)
  }

  getUserInfo (userId: number): Observable<JsonApiResponse<CdbUserGeneralInfoDTO>> {
    return this.http.get<JsonApiResponse<CdbUserGeneralInfoDTO>>(`${this.apiUrl}/cdb/users/${userId}/info`)
  }

  getAutomaticProfileUpdate (): Observable<AutomaticProfileUpdateModel[]> {
    return this.http.get<AutomaticProfileUpdateModel[]>(
      `${this.apiUrl}/cdb/system/task?name=DQUserTask`
    )
  }

  setAutomaticProfileUpdate (
    taskId: string,
    data: AutomaticProfileUpdateModel
  ): Observable<AutomaticProfileUpdateModel> {
    return this.http.put<AutomaticProfileUpdateModel>(
      `${this.apiUrl}/cdb/system/task/${taskId}`,
      data
    )
  }
}
