import { type hr as cpt } from 'efd-cpt-backend-interfaces-ts'

// export type UpcomingAppointmentDTO = ReplaceType<_UpcomingAppointmentDTO, 'startDate' | 'endDate', string>
export type ReplaceType<
  TypeToBeChecked,
  KeyToBeReplaced extends keyof TypeToBeChecked,
  NewValueToUse
> = Omit<TypeToBeChecked, KeyToBeReplaced> & {
  [P in KeyToBeReplaced]?: NewValueToUse
}
// export type ServiceAppointmentDTO = ReplaceDateWithStringType<_ServiceAppointmentDTO>
export type ReplaceDateWithStringType<T> = {
  [P in keyof T]: T[P] extends Date ? string : T[P]
}
export type VehicleDTO =
  ReplaceDateWithStringType<cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.VehicleDTO>
export type VehicleDealersDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.VehicleDealersDTO
export type DealerDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.dealer.dto.DealerDTO
export type LoanDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.LoanDTO
export type imgType = 'brandlogo' | 'vehicleimage'
export type BrandDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.BrandDTO
export interface VehicleDTOExtended extends VehicleDTO {
  dealers?: VehicleDealersDTO
  dealer?: DealerDTO
  brandPicture?: string
  pictureType?: imgType
  active?: boolean
  requestedForRemoval?: boolean
}
