import type { com } from 'efd-cdb_backend-interfaces-ts'

type CdbIDNameDTO = com.mocira.inside.cdb.client.dto.CdbIDNameDTO<number>

export interface UserSearchParameters {
  size: number
  number: number
  search: string
  sort: string
  userType: string
  filter: UserSearchFilter
  dataRestrictions: DataRestrictions
  distinctValues: boolean
  language: string
}

export interface UserSearchFilter {
  dealerName?: string[]
  dealerType?: string[]
  brands?: string[]
  status?: string[]
  roles?: string[]
  status2fa?: boolean[]
  adminRole?: string[]
  lastLoginFrom?: string
  lastLoginTo?: string
  modifiedDtFrom?: string
  modifiedDtTo?: string
  efNetwork?: boolean
}

export interface DataRestrictions {
  noRestrictions: boolean
  importerRestriction: boolean
  dealerRestriction: boolean
  entityId: number[]
  userBrands?: CdbIDNameDTO[]
}
