import { Injectable } from '@angular/core'
import { BaseService } from './base.service'
import { type Observable } from 'rxjs'
import { type JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { type UserSearchParameters } from '../models/user-search.model'
import { HttpParams } from '@angular/common/http'
import { type hr } from 'efd-cdb_backend-interfaces-ts'
import _ from 'lodash'

type CdbUserSearchListUserDTO = hr.emilfreydigital.cdb.dto.CdbUserSearchListUserDTO

@Injectable({
  providedIn: 'root'
})

export class UserSearchService extends BaseService {
  getUsers (params: Partial<UserSearchParameters>): Observable<JsonApiResponseList<CdbUserSearchListUserDTO>> {
    const parameters = this.setParams(params)

    return this.http.get<JsonApiResponseList<CdbUserSearchListUserDTO>>(`${this.apiUrl}/cdb/users/list`, { params: parameters })
  }

  exportUsers (params: Partial<UserSearchParameters>): Observable<Blob> {
    const parameters = this.setParams(params)

    return this.http.get(`${this.apiUrl}/cdb/users/export`, {
      responseType: 'blob',
      params: parameters
    })
  }

  setParams (params: Partial<UserSearchParameters>): HttpParams {
    let parameters = new HttpParams()

    if (params.distinctValues) {
      // parameters = parameters.append('distinct[dealerId]', '')
      parameters = parameters.append('distinct[brands]', '')
      parameters = parameters.append('distinct[roles]', '')
      parameters = parameters.append('distinct[adminRole]', '')
    }

    if (params.size) parameters = parameters.append('page[size]', params.size)
    if (params.number) parameters = parameters.append('page[number]', params.number)
    if (params.search) parameters = parameters.append('search', params.search)
    if (params.sort) parameters = parameters.append('sort', params.sort)
    if (params.language) parameters = parameters.append('language', params.language)
    if (params.filter.lastLoginFrom) {
      const dateFrom = String(Date.parse(params.filter.lastLoginFrom))
      parameters = parameters.append('filter[lastLoginDt][GToE]', dateFrom)
    }
    if (params.filter.lastLoginTo) {
      const dateTo = String(Date.parse(params.filter.lastLoginTo))
      parameters = parameters.append('filter[lastLoginDt][LToE]', dateTo)
    }
    if (params.filter.modifiedDtFrom) {
      const dateFrom = String(Date.parse(params.filter.modifiedDtFrom))
      parameters = parameters.append('filter[modifiedDt][GToE]', dateFrom)
    }
    if (params.filter.modifiedDtTo) {
      const dateTo = String(Date.parse(params.filter.modifiedDtTo))
      parameters = parameters.append('filter[modifiedDt][LToE]', dateTo)
    }
    if (params.filter.status) _.forEach(params.filter.status, el => { parameters = parameters.append('filter[status][IN]', el) })
    if (params.filter.status2fa) _.forEach(params.filter.status2fa, el => { parameters = parameters.append('filter[status2fa]', el) })
    if (params.filter.dealerType) _.forEach(params.filter.dealerType, el => { parameters = parameters.append('filter[dealerType][IN]', el) })
    if (params.filter.dealerName) _.forEach(params.filter.dealerName, el => { parameters = parameters.append('filter[dealerId][IN]', el) })
    if (params.filter.brands) _.forEach(params.filter.brands, el => { parameters = parameters.append('filter[brands][IN]', el) })
    if (params.filter.roles) _.forEach(params.filter.roles, el => { parameters = parameters.append('filter[roles][IN]', el) })
    if (params.filter.adminRole) _.forEach(params.filter.adminRole, el => { parameters = parameters.append('filter[adminRole][IN]', el) })

    if (params.filter.efNetwork != null) {
      parameters = parameters.append('filter[efNetwork][IN]', params.filter.efNetwork)
    }

    if (params.userType) parameters = parameters.append('filter[userType][IN]', params.userType)
    if (params.dataRestrictions.dealerRestriction) {
      if (!params.filter.dealerName?.length) {
        _.forEach(params.dataRestrictions.entityId, el => { parameters = parameters.append('filter[dealerId][IN]', el) })
      }
    }
    if (params.dataRestrictions.importerRestriction) {
      _.forEach(params.dataRestrictions.entityId, el => { parameters = parameters.append('importerRestriction', el) })
    }

    return parameters
  }
}
