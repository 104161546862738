import { Component, Injectable, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'

import { DateAdapter } from '@angular/material/core'
import {
  MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS,
  LegacyNativeDateAdapter as NativeDateAdapter
} from '@angular/material/legacy-core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import {
  ClientConfigService,
  User,
  UserAddress,
  UserConsentsResponse,
  UserService,
  UserTelephone,
  UserUpdateAddressValidationError,
  isDuplicateUserValidationError,
  isUserUpdateAddressValidationError
} from '@inside-hub-app/customer-portal-b2c-client'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'
import { B2CConfig } from '@inside-hub-app/customer-portal-config'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { TranslateService } from '@ngx-translate/core'
import { GoogleTagManagerService } from 'angular-google-tag-manager'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import {
  CountryCallingCode,
  CountryCode,
  getCountryCallingCode,
  parsePhoneNumber
} from 'libphonenumber-js/max'
import * as moment from 'moment'
import { NGXLogger } from 'ngx-logger'
import { delay } from 'rxjs/operators'
import { CustomCalendarComponent } from '../../../components/custom-calendar/custom-calendar.component'
import { InstantErrorStateMatcherService } from '../../../services/instant-error-state-matcher.service'
@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  private static _to2digit(n: number) {
    return ('00' + n).slice(-2)
  }

  getFirstDayOfWeek(): number {
    return 1
  }

  parse(value: any): Date | null {
    if (typeof value === 'string' && value.includes('.')) {
      const str = value.split('.')
      const year = Number(str[2])
      const month = Number(str[1]) - 1
      const date = Number(str[0])
      return new Date(year, month, date)
    }
    const timestamp =
      (typeof value === 'number') != null ? value : Date.parse(value)
    return isNaN(timestamp) != null ? null : new Date(timestamp)
  }

  format(date: Date, displayFormat: string): string {
    if (displayFormat === 'input') {
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      return (
        AppDateAdapter._to2digit(day) +
        '.' +
        AppDateAdapter._to2digit(month) +
        '.' +
        year
      )
    } else if (displayFormat === 'inputMonth') {
      moment.locale('de')
      return moment(date).format('MMMM YYYY')
    } else {
      return date.toDateString()
    }
  }
}

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
  },
  display: {
    dateInput: 'input',
    monthYearLabel: 'inputMonth',
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
}

export interface AddressSuggestionChoice {
  street: string
  streetNumber: string
  streetAdditional: string
  city: string
  zip: string
}

@Component({
  selector: 'customer-portal-app-fast-registration',
  templateUrl: './fast-registration.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS
    }
  ]
})
export class FastRegistrationComponent implements OnInit {
  matcher = new InstantErrorStateMatcherService()
  customHeader = CustomCalendarComponent

  firstNameRegex: string 
  lastNameRegex: string
  addressStreetRegex: string
  addressStreetNumberRegex: string
  addressStreetAdditionalRegex: string
  address: UserAddress
  addressHints: UserAddress[]
  public form

  addSug = ''
  addOptSug = ''
  zipSug = 0
  citySug = ''
  addNumberSug = ''
  public loading: boolean
  hidden: boolean
  disabled = false
  public errorUpdate: boolean
  public zip: number
  public forceSuggestion: boolean
  public salutations
  public titles
  public salutationsWithTitles
  public user: User
  private readonly mobilePrefixCountries: string[]
  public mobilePrefixes: { [key: string]: CountryCallingCode } = {}
  private consentsExist: boolean
  public consents: UserConsentsResponse
  public loggedIn = false
  private redirectUrl = ''
  private apiKey = ''
  public showSuggestions: boolean
  public maxDate
  public duplicateUserValidationError: any
  private readonly icon = 'web/assets/images/create_24px_outlined.svg'
  iconUrl = ''
  addNumberSugForce = false
  public showTelephoneFields

  constructor(
    private readonly userService: UserService,
    private readonly clientConfig: ClientConfigService,
    private readonly keycloak: KeycloakService,
    private readonly logger: NGXLogger,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private readonly translationService: TranslateService,
    private readonly gtmService: GoogleTagManagerService,
    private readonly transifexTranslationsService: TranslationService,
    public remoteConfigService: EfRemoteConfigurationService<B2CConfig>,
    public transifexService: TransifexService
  ) {
    this.loggedIn = this.keycloak.isLoggedIn()
    if (!this.loggedIn) {
      void this.keycloak.login()
    }

    this.firstNameRegex = this.remoteConfigService.get(
      'regEx.nameRegEx.firstName.pattern'
    )
    this.lastNameRegex = this.remoteConfigService.get(
      'regEx.nameRegEx.lastName.pattern'
    )
    this.addressStreetRegex = this.remoteConfigService.get(
      'regEx.addressRegEx.street.pattern'
    )
    this.addressStreetNumberRegex = this.remoteConfigService.get(
      'regEx.addressRegEx.streetNumber.pattern'
    )
    this.addressStreetAdditionalRegex = this.remoteConfigService.get(
      'regEx.addressRegEx.streetAdditional.pattern'
    )

    this.mobilePrefixCountries =
    this.remoteConfigService.get('b2c.mobileCountryPrefixes')

    this.form = new FormGroup({
      accountType: new FormControl<string | null>('personal'),
      salutation: new FormControl<string | null>(
        { value: null, disabled: true },
        [Validators.required]
      ),
      title: new FormControl<string | null>({ value: null, disabled: true }),
      firstName: new FormControl<string | null>(
        { value: null, disabled: true },
        Validators.compose([
          Validators.required,
          Validators.pattern(this.firstNameRegex),
          Validators.maxLength(
            this.remoteConfigService.get('regEx.nameRegEx.firstName.maxLength')
          )
        ])
      ),
      lastName: new FormControl<string | null>(
        { value: null, disabled: true },
        Validators.compose([
          Validators.required,
          Validators.pattern(this.lastNameRegex),
          Validators.maxLength(
            this.remoteConfigService.get('regEx.nameRegEx.lastName.maxLength')
          )
        ])
      ),
      birthday: new FormControl<string | null>({ disabled: true, value: null }, [
        Validators.required
      ]),
      street: new FormControl<string | null>(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.addressStreetRegex),
          Validators.maxLength(
            this.remoteConfigService.get('regEx.addressRegEx.street.maxLength')
          )
        ])
      ),
      streetNumber: new FormControl<string | null>(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.addressStreetNumberRegex)
        ])
      ),
      streetAdditional: new FormControl<string | null>(
        { value: null, disabled: true },
        Validators.compose([
          Validators.pattern(this.addressStreetAdditionalRegex),
          Validators.maxLength(
            this.remoteConfigService.get(
              'regEx.addressRegEx.streetAdditional.maxLength'
            )
          )
        ])
      ),
      zip: new FormControl<string | null>(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(
            String(this.remoteConfigService.get('regEx.addressRegEx.zip.pattern'))
          ),
          Validators.minLength(
            this.remoteConfigService.get('regEx.addressRegEx.zip.minLength')
          ),
          Validators.maxLength(
            this.remoteConfigService.get('regEx.addressRegEx.zip.maxLength')
          )
        ])
      ),
      city: new FormControl<string | null>(null),
      mobilePrefix: new FormControl<string | null>({
        value: null,
        disabled: true
      }),
      mobile: new FormControl<string | null>({ value: null, disabled: true }, [
        control => {
          if (!control || !this.form || !control.value) {
            return {}
          }
  
          if (!this.form.value.mobilePrefix) {
            return { invalidNumber: true }
          }
  
          if (control.value.length < 2) {
            return { invalidNumber: true }
          }
  
          if (!Number(control.value)) {
            return { invalidNumber: true }
          }
  
          const telephoneNumber: string =
            this.form.value.mobilePrefix + control.value
  
          const prefix: string = this.form.value.mobilePrefix
          const prefixEntry = Object.entries(this.mobilePrefixes).find(entry => {
            return entry[1] === prefix.substr(1)
          })
          const prefixCountry = prefixEntry[0] as CountryCode
  
          let parsed = null
          try {
            parsed = parsePhoneNumber(telephoneNumber, prefixCountry)
          } catch {
            return { invalidNumber: true }
          }
  
          if (!parsed.isPossible()) {
            return { invalidNumber: true }
          }
  
          if (!parsed.isValid()) {
            return { invalidNumber: true }
          }
  
          const numberType = parsed.getType()
          if (numberType !== 'MOBILE') {
            return { notMobile: true }
          }
          return {}
        }
      ]),
      language: new FormControl<string | null>(null),
      country: new FormControl<string | null>(null),
      telephone: new FormControl<string | null>(null)
    })
  }

  ngOnInit(): void {
    this.translationService.get('registration-title-tab').subscribe(title => {
      this.titleService.setTitle(title)
    })

    this.route.queryParams.subscribe(param => {
      this.redirectUrl = param.redirect_url
      this.apiKey = param.api_key
      this.checkIfConsentsExist()
    })

    this.iconUrl = `${location.origin}/${this.icon}`

    // restrict 18 yo
    const today = new Date()
    this.maxDate = new Date(today)
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18)

    this.load()

    this.showSuggestions = false
    this.errorUpdate = false

    this.zip = this.remoteConfigService.get('regEx.addressRegEx.zip.minLength')

    this.form.valueChanges.subscribe(value => {
      this.disabled = false
      this.forceSuggestion = false
    })

    if (!this.showTelephoneFields) {
      this.form.controls.mobile.setErrors(null)
      this.form.controls.mobilePrefix.setErrors(null)
    }
  }

  checkIfConsentsExist(): void {
    this.userService.getConsents(null, this.apiKey).subscribe(consents => {
      this.consents = consents
      return consents.group.consents.channels.filter(consent => {
        if (consent.allow) {
          this.consentsExist = true
        }
      })
    })
  }

  load(): void {
    this.userService.enums(this.apiKey).subscribe(user => {
      this.titles = user.titles
      this.salutations = user.salutations
      this.salutationsWithTitles = user.salutationsWithTitles
    })

    this.userService.getUser(this.apiKey).subscribe(user => {
      this.user = user

      this.getMobilePrefixAndPhoneNumber(user)
      this.setPersonalFields()
      this.enableIfNoDataIsProvided()
      this.setStreetFields()

      this.logger.debug(this.user)
      this.loading = false
      this.hidden = true
    })
  }

  setPersonalFields(): void {
    this.form.controls.firstName.setValue(this.user.firstName ?? null)
    this.form.controls.lastName.setValue(this.user.lastName ?? null)
    this.form.controls.birthday.setValue(this.user.birthDate ?? null)
    this.form.controls.salutation.setValue(this.user.salute ?? null)
    this.form.controls.title.setValue(this.user.title ?? null)
  }

  setStreetFields(): void {
    if (this.user.address == null) {
      this.user.address = [
        {
          city: '',
          country: null,
          region: null,
          street: '',
          streetAdditional: '',
          streetNumber: '',
          type: 'home',
          zip: ''
        }
      ]
    } else {
      this.form.controls.street.setValue(this.user.address[0].street)
      this.form.controls.zip.setValue(this.user.address[0].zip)
      this.form.controls.city.setValue(this.user.address[0].city)
      this.form.controls.streetAdditional.setValue(
        this.user.address[0].streetAdditional
      )
      this.form.controls.streetNumber.setValue(
        this.user.address[0].streetNumber
      )
    }
  }

  enableIfNoDataIsProvided(): void {
    Object.keys(this.form.controls).forEach(key => {
      if (key !== 'accountType') {
        if (
          this.form.get('salutation').value !== null ||
          this.form.get('salutation').value
        ) {
          this.form.get(key).markAsTouched()
        }
      }

      if (key !== 'birthday') {
        this.form.get(key).value || this.form.get(key).enable()
      }
      if (key === 'title') {
        this.form.get('salutation').value != null
          ? this.form.get('title').disable()
          : this.form.get('title').enable()
      }
      this.form.get('birthday').enable()
    })
  }

  updateAddress(): void {
    this.disabled = true
    this.loading = true
    this.user.salute = this.form.controls.salutation.value
    this.user.title = this.form.controls.title.value
    this.user.lastName = this.form.controls.lastName.value
    this.user.firstName = this.form.controls.firstName.value
    this.user.birthDate = moment(this.form.controls.birthday.value).format()
    this.duplicateUserValidationError = null
    this.showSuggestions = null

    if (this.user.telephone == null) {
      const mobileNumber =
        this.form.controls.mobile.value != null
          ? this.form.controls.mobilePrefix.value +
            this.form.controls.mobile.value
          : null
      this.user.telephone = [
        {
          type: 'mobile',
          number: mobileNumber,
          usage: 'private'
        }
      ]
    } else if (
      this.user.telephone.find(
        s => s.type === 'mobile' && s.usage === 'private'
      ) != null
    ) {
      this.user.telephone.find(
        s => s.type === 'mobile' && s.usage === 'private'
      ).number =
        this.form.controls.mobilePrefix.value + this.form.controls.mobile.value
    } else if (
      this.user.telephone &&
      this.user.telephone.find(
        s => s.type === 'mobile' && s.usage === 'private'
      ) == null
    ) {
      const mobileNumber =
        this.form.controls.mobile.value != null
          ? this.form.controls.mobilePrefix.value +
            this.form.controls.mobile.value
          : null
      const mobile: UserTelephone = {
        type: 'mobile',
        number: mobileNumber,
        usage: 'private'
      }
      this.user.telephone.push(mobile)
    } else {
      this.user.telephone[0].number =
        this.form.controls.mobilePrefix.value + this.form.controls.mobile.value
    }

    this.user.address[0].street = this.form.controls.street.value
    this.user.address[0].streetNumber = this.form.controls.streetNumber.value
    this.user.address[0].zip = this.form.controls.zip.value
    this.user.address[0].city = this.form.controls.city.value
    this.user.address[0].streetAdditional =
      this.form.controls.streetAdditional.value

    this.userService
      .putUser(this.user, this.apiKey, this.forceSuggestion)
      .subscribe(
        data => {
          this.logger.debug('Request is successful ', data)
          this.hidden = false
          this.loading = false

          if (this.consentsExist) {
            this.clientConfig.get(this.apiKey).subscribe(data => {
              const regex = RegExp(data.allowedRedirectUris).test(
                this.redirectUrl
              )
              if (regex) {
                window.location.href = this.redirectUrl
              }
            })
          } else {
            this.router.navigate(['/user-consents'], {
              queryParams: {
                redirect_url: this.redirectUrl,
                api_key: this.apiKey
              }
            })
          }
        },
        error => {
          this.loading = false
          if (error.status === 500) {
            this.errorUpdate = true
          }

          if (isUserUpdateAddressValidationError(error.error)) {
            this.addressHints = null
            const addressValidationError: UserUpdateAddressValidationError =
              error.error

            if (
              addressValidationError.fields != null &&
              addressValidationError.fields.length > 0 &&
              addressValidationError.fields.find(
                s => s.id === 'address-hints'
              ) != null
            ) {
              this.addressHints = addressValidationError.fields[0].addressHints
            }

            if (
              addressValidationError.fields != null &&
              addressValidationError.fields.length > 0
            ) {
              this.showSuggestions = true
              const streetCorrection = addressValidationError.fields.find(
                f => f.id === 'street'
              )
              this.addSug = null
              this.addOptSug = null
              this.addNumberSug = null
              this.zipSug = null
              this.citySug = null
              if (streetCorrection != null) {
                this.addSug = streetCorrection.correction
              }
              const streetAdditionalCorrection =
                addressValidationError.fields.find(
                  f => f.id === 'street-additional'
                )
              if (streetAdditionalCorrection != null) {
                this.addOptSug = streetAdditionalCorrection.correction
              }
              const streetNumberCorrection = addressValidationError.fields.find(
                f => f.id === 'street-number'
              )
              if (streetNumberCorrection != null) {
                this.addNumberSug = streetNumberCorrection.correction
                if (
                  !streetNumberCorrection.correction &&
                  addressValidationError.fields.length === 1
                ) {
                  this.showSuggestions = false
                  this.forceSuggestion = true
                  this.addNumberSugForce = true
                  this.disabled = false
                }
              }
              const zipCorrection = addressValidationError.fields.find(
                f => f.id === 'zip'
              )
              if (zipCorrection != null) {
                this.zipSug = Number(zipCorrection.correction)
              }
              const cityCorrection = addressValidationError.fields.find(
                f => f.id === 'city'
              )
              if (cityCorrection != null) {
                this.citySug = cityCorrection.correction
              }
            } else if (addressValidationError.fields == null) {
              this.forceSuggestion = true
              this.disabled = false
            }
          } else if (isDuplicateUserValidationError(error.error)) {
            this.duplicateUserValidationError = error.error.message
            this.form.updateValueAndValidity()
            this.disabled = true
          }
          document.getElementById('top').scrollIntoView(true)
        }
      )
  }

  private getUserMobileNumber(telephones, type, usage) {
    if (telephones.find(s => s.type === type && s.usage === usage)) {
      return telephones.map(telephone => {
        if (
          telephone.type === type &&
          telephone.usage === usage
        ) {
          return telephone.number
        }
      })
    } else {
      return null
    }
  }

  private getMobilePrefixAndPhoneNumber(user) {
    // validate mobile after changing prefix
    this.form.controls.mobilePrefix.valueChanges
      .pipe(delay(1))
      .subscribe(prefix => {
        this.form.controls.mobile.updateValueAndValidity()
      })

    // fill calling codes
    for (const countryCodeIndex in this.mobilePrefixCountries) {
      const countryCode = this.mobilePrefixCountries[countryCodeIndex]
      this.mobilePrefixes[countryCode] = getCountryCallingCode(
        countryCode as CountryCode
      )
    }

    if (user.telephone) {
      // get prefix and number
      const telephoneNumber =
        this.getUserMobileNumber(user.telephone, 'mobile', 'private') != null
          ? this.getUserMobileNumber(
              user.telephone,
              'mobile',
              'private'
            ).toString()
          : null

      if (telephoneNumber) {
        let parsedPhoneNumber
        try {
          this.showTelephoneFields = true
          parsedPhoneNumber = parsePhoneNumber(telephoneNumber)
        } catch (e) {
          this.showTelephoneFields = false
          parsedPhoneNumber = parsePhoneNumberFromString(telephoneNumber)
        }

        // fill prefix and number
        this.form.patchValue({
          mobilePrefix:
            parsedPhoneNumber != null
              ? '+' + parsedPhoneNumber.countryCallingCode
              : '',
          mobile:
            parsedPhoneNumber != null
              ? parsedPhoneNumber.nationalNumber.toString()
              : ''
        })
      } else {
        // preselect prefix
        this.showTelephoneFields = true
        if (this.mobilePrefixCountries.length === 1) {
          this.form.patchValue({
            mobilePrefix:
              '+' + this.mobilePrefixes[this.mobilePrefixCountries[0]]
          })
        }
      }
    } else {
      this.showTelephoneFields = true
    }
  }

  changeAddressFields(): void {
    this.showSuggestions = false
    this.form.controls.street.setValue(
      this.addSug != null ? this.addSug : this.form.get('street').value ?? ''
    )
    this.form.controls.zip.setValue(
      this.zipSug != null
        ? String(this.zipSug)
        : this.form.get('zip').value ?? ''
    )
    this.form.controls.city.setValue(
      this.citySug != null ? this.citySug : this.form.get('city').value ?? ''
    )
    this.form.controls.streetAdditional.setValue(
      this.addOptSug != null
        ? this.addOptSug
        : this.form.get('streetAdditional').value ?? ''
    )
    this.form.controls.streetNumber.setValue(
      this.addNumberSug != null
        ? this.addNumberSug
        : this.form.get('streetNumber').value ?? ''
    )
    this.disabled = false
  }

  useAddressHint(address: UserAddress) {
    this.showSuggestions = false
    this.form.controls.street.setValue(
      address.street != null
        ? address.street
        : this.form.get('street').value ?? ''
    )
    this.form.controls.zip.setValue(
      address.zip != null ? address.zip : this.form.get('zip').value ?? ''
    )
    this.form.controls.city.setValue(
      address.city != null ? address.city : this.form.get('city').value ?? ''
    )
    this.form.controls.streetAdditional.setValue(
      address.streetAdditional != null
        ? address.streetAdditional
        : this.form.get('streetAdditional').value ?? ''
    )
    this.form.controls.streetNumber.setValue(
      address.streetNumber != null
        ? address.streetNumber
        : this.form.get('streetNumber').value ?? ''
    )
    this.disabled = false
  }

  getTranslation(): string {
    return this.transifexTranslationsService.translate(
      'Unfortunately, the entered data is not valid. Please check your entries. If your data has been entered correctly, please contact.',
      {
        _key: 'customerPortal.b2c.personalize-new.input-data-error',
        _tags: 'cpt-auth, 3.1'
      }
    )
  }
}
