import { HttpClient } from '@angular/common/http'
import { HubConfigurationService } from '@inside-hub-app/hub-configuration'
import { Injectable } from '@angular/core'
import { type Observable, catchError, throwError, tap, forkJoin, switchMap, EMPTY } from 'rxjs'
import { StorageService } from '@inside-hub-app/hub-storage'
import { StorageEnum } from '../enums/storage.enum'
import type { hr } from 'efd-cdb_backend-interfaces-ts'

type CdbUserContextDto = hr.emilfreydigital.cdb.dto.CdbUserContextDto
type CdbUserContextPartnerInfoDto = hr.emilfreydigital.cdb.dto.CdbUserContextPartnerInfoDto

@Injectable({
  providedIn: 'root'
})

export class UserPartnerContextService {
  apiUrl: string

  constructor (
    private readonly storageService: StorageService,
    private readonly http: HttpClient,
    private readonly hubConfiguration: HubConfigurationService
  ) {
    this.apiUrl = this.hubConfiguration.get<string>('api.pp-url')
  }

  /**
   * Initialize user context on login and set current logged partner
   * @param userId - userId(number)
   * @param loggedPartnerId - loggedPartnerId(number)
   * @returns Observable<CdbUserContextDto>
   */
  initializeUserPartnerContext (userId: number, loggedPartnerId: number): Observable<CdbUserContextDto> {
    return this.handleInitialContextLoad(userId, loggedPartnerId)
  }

  /**
   * Refresh user/partner context after updating user or user partner data
   * @param userId - userId(number)
   * @param loggedPartnerId - loggedPartnerId(number)
   * @returns Observable<CdbUserContextDto> || EMPTY
   */
  refreshUserPartnerContext (userId: number, partnerId: number): Observable<CdbUserContextDto> {
    return this.getCurrentStoredUserAndPartner().pipe(
      switchMap(([user, partner]) => {
        if (user?.id === userId && partner?.id === partnerId) {
          return this.handleInitialContextLoad(userId, partnerId)
        } else {
          return EMPTY
        }
      }),
      catchError(() => { return throwError(() => new Error('Error occurred while refreshing userPartnerContext')) })
    )
  }

  /**
   * Set current partner value (on login/partner switch)
   * @param partner - partner(CdbNestedDealerDTO)
   * @returns void
   */
  setPartner (partner: CdbUserContextPartnerInfoDto): void {
    this.storageService.set(`${StorageEnum.PREFIX}.${StorageEnum.CURRENT_PARTNER}`, partner).subscribe()
  }

  /**
   * Set/update user value
   * @param user - user(CdbUserContextDto)
   * @returns void
   */
  setUser (user: CdbUserContextDto): void {
    this.storageService.set(`${StorageEnum.PREFIX}.${StorageEnum.USER}`, user).subscribe()
  }

  /**
   * Get user context data
   * @returns Observable<CdbUserContextDto>
   */
  getUser (): Observable<CdbUserContextDto> {
    return this.storageService.get(`${StorageEnum.PREFIX}.${StorageEnum.USER}`)
  }

  /**
   * Get current logged/switched partner
   * @returns Observable<CdbUserContextPartnerInfoDto>
   */
  getCurrentPartner (): Observable<CdbUserContextPartnerInfoDto> {
    return this.storageService.get(`${StorageEnum.PREFIX}.${StorageEnum.CURRENT_PARTNER}`)
  }

  /**
   * Clear user and partner context data
   * @returns void
   */
  clearUserPartnerContext (): void {
    this.deleteUserPartnerContextFromStorage()
  }

  getUserContext (userId: number): Observable<CdbUserContextDto> {
    return this.http.get<CdbUserContextDto>(`${this.apiUrl}/cdb/user/user-context/${userId}`)
  }

  /**
   * Get currently stored user and partner
   * @returns Observable<[CdbUserContextDto, CdbUserContextPartnerInfoDto]>
   */
  private getCurrentStoredUserAndPartner (): Observable<[CdbUserContextDto, CdbUserContextPartnerInfoDto]> {
    const getCurrentUser = this.getUser()
    const getCurrentPartner = this.getCurrentPartner()

    return forkJoin([getCurrentUser, getCurrentPartner])
  }

  /**
   * Initial load of user partner context and set current logged in values
   * @param userId - userId(number)
   * @param partnerId - partnerId(number)
   * @returns Observable<CdbUserContextDto>
   */
  private handleInitialContextLoad (userId: number, partnerId: number): Observable<CdbUserContextDto> {
    return this.getUserContext(userId).pipe(
      tap(userContext => {
        this.setUser(userContext)

        const loggedPartner = userContext.partners.find(partner => partner.id === partnerId)
        this.setPartner(loggedPartner)
      }),
      catchError(() => { return throwError(() => new Error('Error occurred while loading userPartnerContext')) })
    )
  }

  /**
   * Delete user and current partner data from storage
   * @returns void
   */
  private deleteUserPartnerContextFromStorage (): void {
    this.storageService.delete(`${StorageEnum.PREFIX}.${StorageEnum.USER}`).subscribe()
    this.storageService.delete(`${StorageEnum.PREFIX}.${StorageEnum.CURRENT_PARTNER}`).subscribe()
  }
}
