import { Injectable } from '@angular/core'
import { type Observable } from 'rxjs'
import { BaseService } from './base.service'

import type { com } from 'efd-cdb_backend-interfaces-ts'

type CdbAttributeDTO = com.mocira.inside.cdb.client.dto.CdbAttributeDTO

@Injectable({
  providedIn: 'root'
})
export class DealerAftersalesLandingPageService extends BaseService {
  getLandingPageConfiguration (dealerId: number): Observable<CdbAttributeDTO> {
    return this.http.get<CdbAttributeDTO>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/attributes/lp_advanced_mode`
    )
  }

  updateLandingPageMode (
    dealerId: number,
    data: CdbAttributeDTO
  ): Observable<CdbAttributeDTO> {
    return this.http.put<CdbAttributeDTO>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/attributes`,
      data
    )
  }
}
